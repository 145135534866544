.loadingcontainer {
  position: relative;
  height: 100vh; /* Make sure the container takes up the full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingbutton {
  padding: 10px 20px;
  font-size: 16px;
}

.loadingoverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensures the overlay is on top */
}

.loadingloader {
  color: white;
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
}
